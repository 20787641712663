.productPricing {
  background-color: #FAF3DD;
}
.costs {
  background-color: #C8D6B9;
}
.gm {
  background-color: #E2DCCA;
}
.gs {
  background-color: #FAF3DD;
}
.exVat {
  background-color: #ADC5E0;
}
.exVatHeading {
  background-color: #769ECB;
}
.discount {
  background-color: #FAF3DD;
}
.perItem, .perPanel {
  background-color: #8FC1A9;
}
.perKw {
  background-color: #7CAA98;
}

.center {
  text-align: center;
}

.horizontalScrollable {
  width: 800px;
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
}
.borderRight {
  //border-right: 1px solid black;
}
