@import '@zing/neo-common/dist/scss/helpers/variables';

.style-guide {
  background-color: #eeeeee;

  section {
    max-width: 1024px;
    margin: 0 auto;
    padding: 40px;
    background-color: #ffffff;
  }

  section + section {
    margin-top: 40px;
  }

  #buttons div + div {
    margin-top: 30px;
  }

  code {
    font-family: monospace;
    display: inline-block;
    font-size: 0.875em;
    padding: 0.15em 0.35em;
    border-radius: 0.2em;
    background-color: $background;
  }
}
