@import '@zing/neo-common/dist/scss/helpers/variables';

.dashboard_nav {
  border-top: 1px solid #bfbfbf;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: $header-z;

  .col + .col {
    border-left: 1px solid #bfbfbf;
  }
  a {
    display: block;
    text-align: center;
    padding: 14px;
    font-weight: 500;
    .icon {
      color: $copy-light;
      margin-right: 10px;
    }
  }
  a.active {
    color: #ffffff;
    background-color: $eon-turquoise;
    .icon {
      color: #ffffff;
    }
  }
}
