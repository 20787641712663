@import '@zing/neo-common/dist/scss/helpers/variables';
@import '@zing/neo-common/dist/scss/helpers/mixins';

.survey-tabs-new {
  padding: 30px 30px 0 30px;
  border-bottom: 2px solid $background;
  //position: sticky;
  top: $headerHeight;
  z-index: $header-z;
  background: #ffffff;

  .survey-tabs__tab.active {
    color: #ffffff;
    background-color: $eon-turquoise;
    // border-color: $eon-turquoise;
  }
}

.survey-tabs__tab {
  display: inline-block;
  vertical-align: bottom;
  font-weight: 900;
  border-top: 2px solid $background;
  border-left: 2px solid $background;
  height: 50px;
  padding: 14px 20px 0 20px;
}

.survey-tabs__tab:first-child {
  border-radius: 6px 0 0 0;
}
.survey-tabs__tab:last-child {
  border-right: 2px solid $background;
  border-radius: 0 6px 0 0;
}

.survey-tabs__tab--edit,
.survey-tabs__tab--settings {
  border-radius: 6px 6px 0 0;
  width: 60px;
  background-repeat: no-repeat;
  background-position: center;
}
.survey-tabs__tab--edit {
  background-size: 25px 28px;
  @include background-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 45"><path d="M0 24.09C0 18.53.12 13 0 7.4c0-2.68.92-3.82 3.67-3.76 5.85.12 11.71 0 17.57.06 1.29 0 3.06-.21 3.07 1.71s-1.73 1.71-3 1.72c-4.81 0-9.62.13-14.42 0-2.47-.13-3.46.48-3.41 3.17q.23 14 0 28c0 2.62.85 3.33 3.36 3.27 6.15-.16 12.31-.15 18.47 0 2.52.06 3.47-.73 3.38-3.33-.16-4.8-.06-9.62 0-14.43 0-1.19-.11-2.73 1.46-2.87 1.84-.15 2.07 1.41 2.07 2.82v18.05c0 2.3-1.1 3.23-3.32 3.22H3.22C.51 45 0 43.52 0 41.24c.08-5.72 0-11.43 0-17.15z" fill="#{$eon-red}"/><path d="M38.25 1.72c-1.94-1.92-3.37-2.54-5.57-.26-5.86 6.05-11.92 11.92-17.9 17.86-2.21 2.19-3.1 5.13-4.4 7.82-.75 1.54-.2 2.63 1.79 2.79 2.74-1.32 5.89-2.18 8.18-4.44 6.11-6 12-12.22 18.21-18.2 2.26-2.2 1.64-3.65-.31-5.57zM17 24.33l-1-1L35 4.19l1 .95z" fill="#{$eon-red}"/></svg>'
  );
}
.survey-tabs__tab--edit.active {
  @include background-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 45"><path d="M0 24.09C0 18.53.12 13 0 7.4c0-2.68.92-3.82 3.67-3.76 5.85.12 11.71 0 17.57.06 1.29 0 3.06-.21 3.07 1.71s-1.73 1.71-3 1.72c-4.81 0-9.62.13-14.42 0-2.47-.13-3.46.48-3.41 3.17q.23 14 0 28c0 2.62.85 3.33 3.36 3.27 6.15-.16 12.31-.15 18.47 0 2.52.06 3.47-.73 3.38-3.33-.16-4.8-.06-9.62 0-14.43 0-1.19-.11-2.73 1.46-2.87 1.84-.15 2.07 1.41 2.07 2.82v18.05c0 2.3-1.1 3.23-3.32 3.22H3.22C.51 45 0 43.52 0 41.24c.08-5.72 0-11.43 0-17.15z" fill="#FFFFFF"/><path d="M38.25 1.72c-1.94-1.92-3.37-2.54-5.57-.26-5.86 6.05-11.92 11.92-17.9 17.86-2.21 2.19-3.1 5.13-4.4 7.82-.75 1.54-.2 2.63 1.79 2.79 2.74-1.32 5.89-2.18 8.18-4.44 6.11-6 12-12.22 18.21-18.2 2.26-2.2 1.64-3.65-.31-5.57zM17 24.33l-1-1L35 4.19l1 .95z" fill="#FFFFFF"/></svg>'
  );
}
.survey-tabs__tab--settings {
  background-size: 30px 30px;
  @include background-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><path stroke="#{$eon-red}" stroke-width="2" d="M15 28a16.423 16.423 0 0 0 1.898-.114.077.077 0 0 0 .067-.066l.393-2.778.613-.184a10.218 10.218 0 0 0 1.762-.713l.562-.291.503.382 1.76 1.336c.028.021.066.02.096-.003.329-.251.891-.73 1.42-1.26l.118-.116a16.382 16.382 0 0 0 1.262-1.423.077.077 0 0 0 0-.094l-1.68-2.238.304-.563c.303-.562.551-1.15.744-1.757l.191-.603.627-.086 2.179-.298a.08.08 0 0 0 .067-.071c.054-.406.114-1.145.114-1.894V15c0-.749-.06-1.48-.115-1.899a.077.077 0 0 0-.065-.066l-2.77-.394-.185-.612c-.183-.61-.423-1.2-.717-1.765l-.292-.562.383-.504 1.333-1.756a.077.077 0 0 0 0-.093 16.355 16.355 0 0 0-1.262-1.424l-.117-.117c-.53-.53-1.09-1.006-1.424-1.262a.076.076 0 0 0-.093 0l-2.24 1.683-.563-.303c-.56-.301-1.147-.55-1.754-.742l-.603-.191-.385-2.813a.077.077 0 0 0-.063-.065A16.428 16.428 0 0 0 15.166 2H15a16.428 16.428 0 0 0-1.898.114.077.077 0 0 0-.067.066l-.393 2.774-.613.184c-.61.183-1.199.422-1.764.715l-.56.291-.504-.382-1.76-1.334a.078.078 0 0 0-.096.003c-.33.253-.89.73-1.42 1.26l-.117.116a16.442 16.442 0 0 0-1.262 1.424.077.077 0 0 0 0 .093l1.682 2.238-.303.564a10.207 10.207 0 0 0-.745 1.755l-.19.602-.627.086-2.183.3a.076.076 0 0 0-.065.063A16.428 16.428 0 0 0 2 14.834V15a16.43 16.43 0 0 0 .114 1.898.077.077 0 0 0 .066.067l2.767.392.184.613c.184.611.423 1.203.717 1.77l.29.56-.38.503-1.33 1.755a.077.077 0 0 0-.001.092 16.354 16.354 0 0 0 1.263 1.425l.117.117a16.417 16.417 0 0 0 1.423 1.262.077.077 0 0 0 .094 0l2.233-1.678.563.304c.564.304 1.152.553 1.76.746l.602.19.087.626.3 2.178a.076.076 0 0 0 .063.065 16.423 16.423 0 0 0 1.901.115H15z"/><path stroke="#{$eon-red}" stroke-width="2" d="M15 20c2.756 0 5-2.244 5-5s-2.244-5-5-5-5 2.244-5 5 2.244 5 5 5z"/></g></svg>'
  );
}
.survey-tabs__tab--settings.active {
  @include background-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><path stroke="#ffffff" stroke-width="2" d="M15 28a16.423 16.423 0 0 0 1.898-.114.077.077 0 0 0 .067-.066l.393-2.778.613-.184a10.218 10.218 0 0 0 1.762-.713l.562-.291.503.382 1.76 1.336c.028.021.066.02.096-.003.329-.251.891-.73 1.42-1.26l.118-.116a16.382 16.382 0 0 0 1.262-1.423.077.077 0 0 0 0-.094l-1.68-2.238.304-.563c.303-.562.551-1.15.744-1.757l.191-.603.627-.086 2.179-.298a.08.08 0 0 0 .067-.071c.054-.406.114-1.145.114-1.894V15c0-.749-.06-1.48-.115-1.899a.077.077 0 0 0-.065-.066l-2.77-.394-.185-.612c-.183-.61-.423-1.2-.717-1.765l-.292-.562.383-.504 1.333-1.756a.077.077 0 0 0 0-.093 16.355 16.355 0 0 0-1.262-1.424l-.117-.117c-.53-.53-1.09-1.006-1.424-1.262a.076.076 0 0 0-.093 0l-2.24 1.683-.563-.303c-.56-.301-1.147-.55-1.754-.742l-.603-.191-.385-2.813a.077.077 0 0 0-.063-.065A16.428 16.428 0 0 0 15.166 2H15a16.428 16.428 0 0 0-1.898.114.077.077 0 0 0-.067.066l-.393 2.774-.613.184c-.61.183-1.199.422-1.764.715l-.56.291-.504-.382-1.76-1.334a.078.078 0 0 0-.096.003c-.33.253-.89.73-1.42 1.26l-.117.116a16.442 16.442 0 0 0-1.262 1.424.077.077 0 0 0 0 .093l1.682 2.238-.303.564a10.207 10.207 0 0 0-.745 1.755l-.19.602-.627.086-2.183.3a.076.076 0 0 0-.065.063A16.428 16.428 0 0 0 2 14.834V15a16.43 16.43 0 0 0 .114 1.898.077.077 0 0 0 .066.067l2.767.392.184.613c.184.611.423 1.203.717 1.77l.29.56-.38.503-1.33 1.755a.077.077 0 0 0-.001.092 16.354 16.354 0 0 0 1.263 1.425l.117.117a16.417 16.417 0 0 0 1.423 1.262.077.077 0 0 0 .094 0l2.233-1.678.563.304c.564.304 1.152.553 1.76.746l.602.19.087.626.3 2.178a.076.076 0 0 0 .063.065 16.423 16.423 0 0 0 1.901.115H15z"/><path stroke="#ffffff" stroke-width="2" d="M15 20c2.756 0 5-2.244 5-5s-2.244-5-5-5-5 2.244-5 5 2.244 5 5 5z"/></g></svg>'
  );
}

.survey-tabs__tab.complete,
.survey-tabs__tab.invalid {
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background-size: 100%;
    position: absolute;
    top: -12px;
    right: 1px;
  }
}

.survey-tabs__tab.complete:after {
  @include background-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><circle cx="12.5" cy="12.5" r="12.5" fill="#1ea2b1"/><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M18.78 8.82l-8.69 8.28-3.33-3.33"/></svg>'
  );
}
.survey-tabs__tab.invalid:after {
  @include background-svg(
    '<svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg"><circle cx="12.5" cy="12.5" fill="#ea1c0a" r="12.5"/><g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"><path d="m16.84 8.36-8.68 8.28"/><path d="m16.64 16.84-8.28-8.68"/></g></svg>'
  );
}

.survey-tabs__tab.active.complete:after {
  @include background-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><circle cx="12.5" cy="12.5" r="12.5" fill="#1ea2b1" /><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M18.78 8.82l-8.69 8.28-3.33-3.33"/></svg>'
  );
}
