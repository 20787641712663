@import '@zing/neo-common/dist/scss/helpers/variables';

.package-details {
  padding: 40px 30px;
}

.package-item {
  margin-bottom: 25px;

  .hollywood-select {
    margin-bottom: 15px;
  }

  ul li {
    position: relative;
    padding-left: 1.25em;
    margin-bottom: 10px;

    &:before {
      content: '\2022';
      display: inline-block;
      font-size: 2em;
      line-height: 0.5;
      position: absolute;
      left: 0;
    }
  }

  .package-item__details {
    padding-left: 25px;
    border-left: 5px solid;
  }
}

.package-item.solar-package {
  .hollywood-select {
    border-color: $eon-lime;
  }
  ul li:before {
    color: $eon-lime;
  }
  .package-item__details {
    border-color: $eon-lime;
  }
}
.package-item.solar-panel {
  .hollywood-select {
    border-color: $eon-lime;
  }
  ul li:before {
    color: $eon-lime;
  }
  .package-item__details {
    border-color: $eon-lime;
  }
}

.package-item.inverter {
  .hollywood-select {
    border-color: $eon-turquoise;
  }
  ul li:before {
    color: $eon-turquoise;
  }
  .package-item__details {
    border-color: $eon-turquoise;
  }
}

.package-item.battery,
.package-item.solar-package {
  .hollywood-select {
    border-color: $eon-red;
  }
  ul li:before {
    color: $eon-red;
  }
  .package-item__details {
    border-color: $eon-red;
  }
}

.house-drawing {
  display: block;
  margin-top: 100px;
}
