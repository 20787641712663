@import '@zing/neo-common/dist/scss/helpers/variables';

.opportunity_time {
  margin-bottom: 2px;
}

.notes {
  margin-left: 40px;
  margin-top: 12px;
}

.chipRoot {
  color: #fff;
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: bold;
  box-sizing: border-box;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #616161;

  .chipLabel {
    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
  }

  &.cancelled {
    background-color: $eon-red;
  }
}
