.diagnostics {
  .productList {
    margin-top: 64px;
  }
  table {
    .prodId,
    .name,
    .componentColumn,
    .bullets {
      vertical-align: top;
    }
    .componentColumn {
      text-align: left;
      font-style: italic;

      img {
        background-color: white;
        border: 1px solid #bfbfbf;
      }
    }

    .bullets {
      ul {
        list-style: disc;
        margin-left: 20px;
      }
    }
  }
}
