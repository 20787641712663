@import '@zing/neo-common/dist/scss/helpers/variables';

.create-opportunity {
  padding-top: 0;
  fieldset {
    max-width: 1024px;
    margin: 0 auto 44px auto;
    border-top: 1px solid $background;
    padding-top: 44px;
  }

  .hollywood-input {
    max-width: 240px;
  }
  [name='title'] {
    width: 80px;
  }
  [name='address.postcode'] {
    width: 200px;
  }
  [for='account.different_invoice_address'] {
    margin-top: 285px;
  }
}
