@import '@zing/neo-common/dist/scss/helpers/variables';

.presenter {
  padding: 0;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.presenter__slide {
  width: 100vw;
  height: 100vh;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  // its an iframe which is inline-block by default
}

.presenter__buttons {
  position: absolute;
  bottom: 30px;
  right: 20px;
  .icon-btn,
  .hollywood-btn {
    margin-right: 10px;
  }
}

.presenter__buttons.light {
  color: #ffffff;
}
.presenter__buttons.dark {
  color: $eon-red;
}
