@import '@zing/neo-common/dist/scss/helpers/variables';

.g99-application {
  .show-applicant-details button {
    color: $eon-red;
    margin-right: 10px;
  }

  button:focus {
    outline: 0;
  }

  .applicant-details {
    margin-bottom: 20px;
  }

  .declaration {
    margin: 20px 0;
  }

  .actions {
    margin: 20px 0;
  }
  .actions span {
    display: inline-block;
    margin: 0 1em;
  }
}
