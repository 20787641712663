@import '@zing/neo-common/dist/scss/helpers/variables';

.map {
  height: 420px;
  background-color: $background;
  border-radius: 8px;
  overflow: hidden; // crop rounded corners
}
.map-pin {
  display: block;
  width: 40px;
  height: 54px;
  transform: translate(-20px, -54px);
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
