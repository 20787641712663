.panel {
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  overflow: hidden; // crop rounded corners
  padding: 20px;
  margin-bottom: 50px;
}

.panel--flush {
  padding: 0;
}
