@import '@zing/neo-common/dist/scss/helpers/variables';

.opportunities_filter {
  display: flex;
  button {
    border: 2px solid $background;
    font-weight: 900;
    padding: 5px 0;
    font-size: 14px;
    width: 90px;
    outline: none;
  }
  button:focus {
    outline: none;
    background: $background;
  }
  button:first-child {
    border-radius: 5px 0 0 5px;
  }
  button:last-child {
    border-radius: 0 5px 5px 0;
  }
  button + button {
    margin-left: -2px; // make borders overlap
  }
  button.active {
    background: $eon-turquoise;
    color: #ffffff;
    border-color: $eon-turquoise;
  }
}
