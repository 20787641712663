@import '@zing/neo-common/dist/scss/helpers/variables';

.pipeline_nav {
  position: fixed;
  top: $headerHeight;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid $background;
  padding: 0 30px 20px 30px;
  z-index: $header-z - 2;
}

.pipeline_filter {
  display: flex;
  a {
    border: 2px solid $background;
    font-weight: 900;
    padding: 5px 0;
    font-size: 14px;
    width: 180px;
    outline: none;
    text-align: center;
  }

  a:first-child {
    border-radius: 5px 0 0 5px;
  }
  a:last-child {
    border-radius: 0 5px 5px 0;
  }
  a + a {
    margin-left: -2px; // make borders overlap
  }
  a.active {
    background: $eon-turquoise;
    color: #ffffff;
    border-color: $eon-turquoise;
  }
  .customerSearch.active {
    background: $eon-lime;
    color: #000000;
    border-color: $eon-lime;
  }
}
