@import '@zing/neo-common/dist/scss/helpers/variables';

.survey-page {
  position: relative;
  min-height: 0;

  fieldset {
    padding-bottom: 25px;
    border-bottom: 2px solid $background;
    margin-bottom: 25px;
  }

  label {
    font-size: 16px;
    line-height: 20px;
  }
  label:not(.hollywood-checkbox-label):not(.hollywood-radio-label) {
    margin: 0;
  }
  .hollywood-radio-label,
  .hollywood-checkbox-label {
    margin-right: 0;
  }

  .right-col {
    min-width: 300px;
    max-width: 100%;
    text-align: right;
  }
}

.survey-actions {
  padding: 0 30px 30px 30px;
}

.your-package-header-buttons {
  position: absolute;
  top: 51%;
  right: 150px;
  transform: translateY(-50%);
  z-index: 10;
}