@import '@zing/neo-common/dist/scss/helpers/variables';

.trouble-sign-in {
  padding: 0 30px 30px 30px;
  height: 90vh;

  h2 {
    margin-bottom: 12px;
  }
  .tagline {
    font-style: italic;
    margin-bottom: 80px;
    color: $copy-light;
  }

  .info {
    margin-bottom: 60px;
  }

  a {
    color: $eon-red;
  }
  a:hover {
    color: $eon-red-hover;
  }

  div.back {
    text-align: right;
  }
}
