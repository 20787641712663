@import '@zing/neo-common/dist/scss/helpers/variables';

.batteries__headings,
.batteries__row {
  border-bottom: 1px solid $background;
  .battery-col {
    width: 460px;
  }
}

.batteries__headings {
  padding-bottom: 5px;
}

.batteries__row {
  padding: 20px 0;
}

.batteries__total {
  padding: 20px 0;
  padding-bottom: 25px;
  border-bottom: 2px solid #e8e8e8;
  margin-bottom: 25px;
}

fieldset.no-bottom-border {
  border-bottom: 0 !important;
}

.battery-warning {
  font-size: larger;
  color: $copy-light;
}
