@import '@zing/neo-common/dist/scss/helpers/variables';

.email-quote {
  h2 .icon {
    margin-right: 10px;
  }

  .quote-option {
    padding: 15px 0;
    border-top: 1px solid $background;
  }
}
