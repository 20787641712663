@import '@zing/neo-common/dist/scss/helpers/variables';
@import '@zing/neo-common/dist/scss/helpers/mixins';

.poi-checkboxes {
  .hollywood-checkbox-label {
    display: inline-block;
    vertical-align: bottom;
    padding: 0 20px 15px 20px;
    border: 2px solid $background;
    border-radius: 10px;
    text-align: center;
    position: relative;
    font-weight: 900;
    width: 210px;
    font-size: 15px;
    margin: 0;

    svg {
      display: block;
      margin: 0 auto;
      color: $eon-red;
    }

    &:before {
      top: 10px;
      right: 10px;
      left: auto;
    }
  }

  .hollywood-checkbox-label ~ .hollywood-checkbox-label {
    margin-left: 20px;
  }

  .hollywood-checkbox-label:nth-of-type(even) {
    margin-left: 20px;
  }

  .hollywood-checkbox:checked + .hollywood-checkbox-label {
    color: #ffffff;
    background-color: $eon-turquoise;
    border-color: $eon-turquoise;
    &:before {
      @include background-svg(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M1.62 1.63C2.69.42 5.65-.11 10.5 0c4.85-.13 7.81.4 8.88 1.61S21 5.8 21 10.5s-.54 7.66-1.62 8.87-4 1.74-8.88 1.61c-4.85.13-7.81-.4-8.88-1.61S0 15.2 0 10.5s.54-7.66 1.62-8.87z" fill="#ffffff"/><path fill="none" stroke="#{$eon-red}" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10.5l3.5 4 8-7.5"/></svg>'
      );
    }
    svg {
      color: #ffffff;
    }
  }
}
