main.diagnostics table.pvTable {
  margin-bottom: 80px;
  font-size: 16px;

  th.occupancyHeader {
    background-color: silver;
    font-weight: bold;
    padding: 16px;
    font-size: 20px;
  }
  th.batterySubHeader {
    background-color: #cecece;
    font-weight: bold;
    padding: 16px;
    font-size: 20px;
  }
  td.batteryRangeHeader {
    background-color: #ececec;
    font-weight: bold;
    text-align: right;
    padding-right: 12px;
  }
  td.clampedSection {
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    border-bottom: 1px solid silver;
  }
  td.valueP {
    text-align: right;
    padding-right: 10px;
    border-bottom: 1px solid silver;
  }
}
