@import '@zing/neo-common/dist/scss/helpers/variables';

$largeIconSize: 2.5em;

.icon {
  &.sales_focus_col_icon {
    width: $largeIconSize;
    height: $largeIconSize;
    padding: 0;
    margin: 0;
    color: $eon-red;
  }
}
