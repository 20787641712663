@import '@zing/neo-common/dist/scss/helpers/variables';

.login {
  color: #ffffff;
  background: $eon-red;
  padding: 0;

  .eon-logo {
    position: absolute;
    top: 0;
    right: 0;
  }

  .image-col,
  .login-col {
    height: 100vh;
  }
  .image-col {
    border-right: 10px solid $eon-turquoise;
    background-image: url('./login-background.jpg');
    background-size: cover;
    background-position: center center;
  }
  .login-col {
    width: 490px;
    padding: 80px;
    border-left: 28px solid $eon-lime;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .auth-error {
    background: #ffffff;
    border-radius: 6px;
    padding: 10px;
    color: $eon-red;
    font-weight: bold;
  }

  .login_username_link {
    margin-top: 24px;
  }
}
