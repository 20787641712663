@import '@zing/neo-common/dist/scss/helpers/variables';

.opportunities_nav {
  position: fixed;
  top: $headerHeight;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid $background;
  padding: 0 30px 20px 30px;
  z-index: $header-z - 2;
}

.opportunities_calendar_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: $header-z + 1;
}

.opportunities_calendar {
  position: absolute;
  top: 60px;
  right: 30px;
  background: #ffffff;
  padding: 20px;
  font-weight: 700;
  font-size: 14px;

  .close_calendar {
    position: absolute;
    top: 20px;
    right: 17px;
    z-index: 1;
  }

  .react-datepicker {
    position: relative;
    padding-bottom: 40px; // clear space for navigation
  }

  // Wrapper triangle
  .react-datepicker__triangle {
    display: none;
  }

  // Header
  .react-datepicker__header {
    text-align: center;
  }
  .react-datepicker__current-month {
    font-weight: 900;
    font-size: 20px;
    margin: 5px 0 20px 0;
  }

  // Weeks
  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    width: 100%;
  }
  .react-datepicker__day-names {
    margin-bottom: 10px;
  }
  // Days
  .react-datepicker__day-name,
  .react-datepicker__day {
    flex: 1;
    text-align: center;
  }
  .react-datepicker__day-name {
    font-weight: 900;
  }
  .react-datepicker__day {
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 17px;
    margin: 3px;
    cursor: pointer;
  }
  .react-datepicker__day--outside-month {
    color: $background;
  }
  // .react-datepicker__day:hover,
  // .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--highlighted {
    background-color: $background;
    color: $copy;
  }
  .react-datepicker__day--selected {
    background-color: $eon-red !important;
    color: #ffffff;
  }

  // Navigation Buttons
  .react-datepicker__navigation {
    position: absolute;
    bottom: 0;
    text-indent: -99999px;
    display: block;
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'%3E%3Cpath d='M13.8 26.15h22' fill='none' stroke='%23ea1c0a' stroke-linecap='round' stroke-width='3'/%3E%3Cpath fill='none' stroke='%23ea1c0a' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M27 13.97l11 11.98-11 11.98'/%3E%3Cpath d='M4.75 4.71C2.3 7.45 1 14.6 1 26s1.3 18.55 3.75 21.29S14.27 51.28 26 51c11.7.33 18.83-1 21.22-3.66S51 37.4 51 26 49.7 7.45 47.25 4.71 37.73.72 26 1.05C14.27.72 7.14 2 4.75 4.71z' fill='none' stroke='%23ea1c0a' stroke-width='2'/%3E%3C/svg%3E");
    background-size: 30px 30px;
  }
  .react-datepicker__navigation--previous {
    left: 3px;
    transform: rotate(-180deg);
  }
  .react-datepicker__navigation--next {
    right: 3px;
  }
}
.opportunities_calendar__key {
  text-align: center;
  margin-top: -25px;
  li {
    display: inline-block;
    margin: 0 0.5em;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: top;
      margin: 0.2em 0.3em 0 0;
      width: 1em;
      height: 1em;
      border-radius: 0.5em;
    }
  }
  li.future:before {
    background: $background;
  }
  li.selected:before {
    background: $eon-red;
  }
}
