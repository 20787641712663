@import '@zing/neo-common/dist/scss/helpers/variables';

.settings {
  .connection-speed-button {
    color: $eon-red;
    text-decoration: underline;
  }

  span.label {
    font-weight: bold;
  }

  span.value {
    font-weight: normal;
    color: $copy-light;
    float: right;
  }

  span.offline-warning {
    color: $eon-red;
    font-weight: bold;
  }

  p.last-updated {
    font-size: 0.9em;
    margin-top: 10px;
    color: $copy-light;
  }

  li {
    border-bottom: 1px solid #bfbfbf;
    min-height: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  div.support-helpdesk {
    border: 2px solid $eon-red;
    border-radius: 6px;
    padding: 20px;

    a[href^='mailto'] {
      color: $eon-red;
      font-weight: bold;
    }
  }

  div.profilePicture {
    width: 400px;
    margin: 0 auto;

    .circle {
      text-align: center;
      width: 100px;
      height: 100px;
      border: 1px solid #bfbfbf;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
    }

    .profilePictureIcon {
      margin-top: 10px;
      font-size: 35px;
      color: $eon-red;
    }

    .profilePictureImage {
      width: 98px;
      height: 98px;
      border: 1px solid #bfbfbf;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
    }

    .profileButton {
      margin-top: 10px;
    }

    .removeProfilePictureButton {
      font-size: 12px;
      display: block;
      float: right;
      margin-right: 40px;
      top: 0;
      cursor: pointer;
    }
  }
}

.update-user-button-wrapper {
  float: right;
}

.settings-nav {
  border-top: 1px solid #bfbfbf;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;

  .col + .col {
    border-left: 1px solid #bfbfbf;
  }
  a {
    display: block;
    text-align: center;
    padding: 14px;
    font-weight: 500;
    .icon {
      color: $copy-light;
      margin-right: 10px;
    }
  }
  a.active {
    color: #ffffff;
    background-color: $eon-red;
    .icon {
      color: #ffffff;
    }
  }
}

.app-version {
  //text-align: right;
  font-size: 0.8px;
}
