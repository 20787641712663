@import '@zing/neo-common/dist/scss/helpers/variables';

$costs: #c8d6b9;
$exVat: #adc5e0;
$gm: #e2dcca;
$gm2: #cecabb;
$gs: #faf3dd;



.dataDrivenProductTable {
  margin-bottom: 40px;
  width: 100%;

  th {
    font-weight: bolder;
    padding: 8px;
    position: relative;

    span.tinyVat {
      font-weight: normal;
    }
  }

  td {
    padding: 8px;
  }
  .results_table {
    th {
      font-weight: bolder;
      text-align: left;
      padding: 8px;
      background-color: #39393a;
      color: white;

    }
    td {
      text-align: left;
      padding: 8px;
    }
  }

  .padded_table .col {
    margin-bottom: 12px;
  }

  .productLevel0 {
    background-color: #e6e6e6;
  }
  .productLevel1 {
    background-color: $background-light;
  }
  .productLevel2 {
  }
  .productPricing {
    background-color: #faf3dd;
  }
  .costs {
    background-color: $costs;
  }
  .gm {
    background-color: $gm;
  }
  .gm2 {
    background-color: $gm2;
  }
  .gs {
    background-color: $gs;
  }
  .exVat {
    background-color: $exVat;
  }
  .exVatHeading {
    background-color: #769ecb;
  }
  .discount {
    background-color: #faf3dd;
  }
  .perItem,
  .perPanel {
    background-color: #8fc1a9;
  }
  .perKw {
    background-color: #7caa98;
  }

  // borders
  .blgm {
    border-left: 1px solid $gm;
  }

  th, td {
    &.left {
      text-align: left;
    }
    &.money, &.right {
      text-align: right;
    }
    &.center {
      text-align: center;
    }
  }

  th {
    &.noBorder {
      border: 0;
    }
  }

  .totalsRow {
    td {
      border-top: 1px solid #afaa9b;
      font-style: italic;
      &.noBorder {
        border: 0;
      }
      &.total {
        text-align: right;
      }
    }
  }
}

.horizontalScrollable {
  width: 800px;
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
}
.borderRight {
  //border-right: 1px solid black;
}



.padded_table {
  th, td {
    &.left {
      text-align: left;
    }
    &.money, &.right {
      text-align: right;
    }
    &.center {
      text-align: center;
    }
  }
}
