@import '@zing/neo-common/dist/scss/helpers/variables';

.carbon-footprint__content {
  padding: 30px 90px;
}

.carbon-footprint__image {
  width: 430px;
  background-size: cover;
  background-position: right center;
  position: relative;
  border-right: 35px solid $eon-bordeaux; // rightmost border
  &:after {
    // inner border
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 10px;
    background: $eon-turquoise;
  }
}

.carbon-footprint__chart {
  position: relative;
}
.carbon-footprint__chart__text {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
  font-size: 36px;
  white-space: nowrap;
}

.carbon-footprint__chart.solar {
  .percentage {
    stroke: $eon-lime;
  }
}
