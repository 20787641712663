@import '@zing/neo-common/dist/scss/index.scss';
@import '@zing/neo-common/dist/scss/helpers/variables';

.generating {
  position: relative;

  .percentageDoneWrapper {
    margin-top: 20px;
    width: 300px;
    background-color: $background-light;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 10px;
    .percentageDone {
      width: 0%;
      height: 40px;
      line-height: 40px;
      white-space: nowrap;
      background-color: $eon-bordeaux;
      color: white;
      border-radius: 10px;
      font-size: 12px;
    }
  }
  .processing {
    height: 80px;
    line-height: 80px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
  }
}
