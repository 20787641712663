@import '@zing/neo-common/dist/scss/helpers/variables';
@import '@zing/neo-common/dist/scss/helpers/mixins';

.error-message {
  margin: 20px 0;
  padding: 10px 60px;
  background-color: $eon-red;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  @include background-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><path fill="#ffffff" fill-rule="nonzero" d="M26.8 23.2L16.8 5c-.6-1-1.9-1.4-2.9-.8-.3.2-.6.5-.8.8l-10 18.2c-.5 1 0 2.2 1 2.7.3.1.6.2.8.2H25c1.1 0 2-.9 2-2 0-.4-.1-.7-.2-.9zM14 10c0-.6.4-1 1-1s1 .4 1 1v7c0 .6-.4 1-1 1s-1-.4-1-1v-7zm1 13c-.8 0-1.5-.7-1.5-1.5S14.2 20 15 20s1.5.7 1.5 1.5S15.8 23 15 23z"/></g></svg>'
  );
  background-size: 30px 30px;
  background-position: 15px center;
  background-repeat: no-repeat;
}
