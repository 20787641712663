@import '@zing/neo-common/dist/scss/helpers/variables';

// Shared styles go in here
.your-package {
  padding: 0;
  position: relative;
  margin-top: 30px;

  .page-heading {
    position: absolute;
    top: 30px;
    right: 30px;
    max-width: 450px;
    .icon-btn {
      margin-top: 5px;
    }
  }

  #comparison {
    margin-left: 30px;
    margin-bottom: 30px;
  }
  .comparisonButton {
    margin-left: 48px !important;
  }
  .benefits-message {
    color: $eon-bordeaux;
    background-color: $eon-lime;
    padding: 14px 22px;
    border-radius: 12px;
    display: inline-block;
  }

  .actions {
    padding: 30px;
  }
}
