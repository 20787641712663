@import '@zing/neo-common/dist/scss/helpers/variables';

.additional-products__headings,
.additional-products__row {
  .product-col {
    width: 360px;
  }
}

.additional-products__headings {
  font-size: 1.175rem;
  padding-bottom: 15px;
}
.additional-products__headings:not(:first-child) {
  margin-top: 30px;
}

.additional-products__row {
  border-top: 1px solid $background;
  padding: 15px 0;
}
.additional-products__total {
  padding: 20px 0;
}

.installation-notes-modal .hollywood-modal__content {
  width: 600px;
}
