@import '@zing/neo-common/dist/scss/helpers/variables';

.opportunity-details-row:not(.borderless) {
  padding-bottom: 12px;
  border-bottom: 1px solid $background;
  margin-bottom: 12px;
}

.appointment_details-row:not(.borderless) {
  padding-bottom: 12px;
  border-bottom: 1px solid $background;
  margin-bottom: 12px;
}

.opportunity-actions {
  position: sticky;
  bottom: 0;
}

.details_date_time {
  margin-bottom: 40px;

  h3 {
    margin-bottom: 10px;
  }
  .meeting_detail {
    color: $copy-light;
  }
}
