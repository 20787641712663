@import '@zing/neo-common/dist/scss/helpers/variables';

main.diagnostics {
  //   background-color: #e5e5e5;
  min-height: calc(100% - 70px);
  font-size: 1.2em;

  // tables
  table {
    width: 100%;
    table-layout: fixed;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .tableHeaderRowTitle {
    padding-top: 64px;
    padding-left: 0;
  }
  td {
    padding: 16px 6px;
  }
  .tableHeaderRowTitle h2 {
    margin-left: 0;
    padding: 12px 6px;
  }
  .subHeading {
    font-weight: bold;
    font-size: 1em;
    padding: 12px 6px;
  }
  tr.highlight-row {
    background-color: #f3f3f3;
  }
  tr.selected {
    background-color: #cccccc;
  }
  td.nowrap {
    white-space: nowrap;
  }

  .diagnosticsActions {
    margin-top: 48px;
  }

  .users-filter {
    margin-bottom: 30px;
    text-align: center;
  }
  .users-filter__btn {
    border: 2px solid $background;
    font-weight: 900;
    padding: 5px 0;
    font-size: 14px;
    width: 120px;
    outline: none;
    text-align: center;
  }
  .users-filter__btn:first-child {
    border-radius: 5px 0 0 5px;
  }
  .users-filter__btn:last-child {
    border-radius: 0 5px 5px 0;
  }
  .users-filter__btn + .users-filter__btn {
    margin-left: -2px;
  }
  .users-filter__btn--active {
    background: $eon-turquoise;
    color: #ffffff;
    border-color: $eon-turquoise;
  }
}

// diagnostics header
.diagnostics-header-buttons {
  position: absolute;
  top: 51%;
  right: 24px;
  transform: translateY(-50%);
  z-index: 10;
}
