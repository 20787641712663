@import '@zing/neo-common/dist/scss/helpers/variables';

.powra-form__section {
  margin-bottom: 40px;
}
.powra-form__field + .powra-form__field {
  margin-top: 10px;
  border-top: 2px solid $background;
  padding-top: 10px;
}
