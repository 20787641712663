@import '@zing/neo-common/dist/scss/helpers/variables';

.thank-you-meeting-us {
  padding: 0;
  height: 100vh;
  background-color: $eon-turquoise;

  .image-col,
  .content-col {
    height: 100vh;
  }

  .image-col {
    border-left: 15px solid $eon-red-hover;
    border-right: 23px solid $eon-lime;
    background-image: url('./thank-you-meeting-us-background.jpg');
    background-size: cover;
    background-position: left center;
  }
  .content-col {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    color: #ffffff;
  }

  .eon-logo {
    width: 286px;
    height: 140px;
  }

  h1 {
    font-size: 70px;
    line-height: 74px;
    padding: 30px;
  }

  .actions {
    padding: 30px;
  }
}
