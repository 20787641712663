@import '@zing/neo-common/dist/scss/helpers/variables';
@import '@zing/neo-common/dist/scss/helpers/mixins';

.payment-options {
  padding: 20px 490px 0 0;

  .title {
    margin-bottom: 50px;
    svg,
    h1 {
      display: inline-block;
      vertical-align: top;
    }

    svg {
      margin-right: 20px;
    }
  }
  .narrative_default_text {
    display: block;
    unicode-bidi: embed;
    line-height: 1.6rem;
    margin: 12px 20px 20px 20px;
    word-wrap: break-word;
    white-space: pre-wrap;

    ul {
      margin-left: 30px;
      list-style: disc;
    }
  }

  .breakdown-col {
    width: 460px;
    height: 100vh;
    background: $eon-turquoise;
    position: fixed;
    top: 0;
    right: 0;
    z-index: $header-z;

    .breakdown-col-img,
    .breakdown-col-content {
      height: 50%;
    }

    .breakdown-col-img {
      background-image: url('image.png');
      background-size: cover;
      background-position: center center;
      .eon-logo {
        float: right;
      }
    }

    .breakdown-col-content {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .breakdown-panel-wrapper {
      flex: 1;
    }
    .breakdown-panel {
      background: #ffffff;
      border-radius: 8px;
      padding: 20px;
    }

    .breakdown-table {
      table-layout: fixed;
      width: 100%;

      td {
        padding: 8px 0;
      }
      .amount {
        text-align: right;
      }

      .total-cost {
        border-bottom: 1px solid $background;
        td {
          padding-top: 0;
        }
      }

      .post-install {
        td {
          padding: 20px 0 0 0;
        }
        .amount {
          font-size: 1.5em;
        }
      }
    }
  }
}
