@import '@zing/neo-common/dist/scss/helpers/variables';
@import '@zing/neo-common/dist/scss/helpers/mixins';

.app-menu,
.diagnostics-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -270px;
  width: 270px;
  transform: translateX(0);
  // transform: translateX(100%);
  transition: transform 250ms ease;
  z-index: $menu-z;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px 30px 30px 30px;
  background-color: $eon-red;
  color: #ffffff;
  font-weight: 500;

  h3 {
    padding-bottom: 18px;
    border-bottom: 1px solid #ffffff;
    margin-bottom: 25px;
  }
  h3:not(:first-child) {
    margin-top: 50px;
  }

  li {
    margin: 15px 0;
  }

  .icon {
    margin-right: 15px;
  }
}

.diagnostics-menu {
  background-color: #121212;
}

.app-menu__body,
.app-menu__footer,
.diagnostics-menu__body,
.diagnostics-menu__footer {
  flex: none;
}

.app-menu__footer,
.diagnostics-menu__footer {
  justify-self: bottom;
}

.app-menu--open,
.diagnostics-menu--open {
  transform: translateX(100%);
}

.app-menu__close-btn,
.app-menu__logout-btn,
.diagnostics-menu__close-btn,
.diagnostics-menu__logout-btn {
  @include button-reset;
}

.app-menu__close-btn,
.diagnostics-menu__close-btn {
  position: absolute;
  top: 20px;
  left: 30px;
}
