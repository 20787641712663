@import '@zing/neo-common/dist/scss/helpers/variables';

.stat_container {
  padding: 40px 30px;
  &.lime {
    background: $eon-lime;
    color: $eon-bordeaux;
  }
  &.bordeaux {
    background: $eon-bordeaux;
    color: #ffffff;
  }
  &.turquoise {
    background: $eon-turquoise;
    color: #ffffff;
  }
}
