@import '@zing/neo-common/dist/scss/helpers/variables';

.future-cashflow {
  padding: 90px 30px 0 30px;
  &.expanded {
    padding: 0px 30px 0 30px;
  }
}

.future-cashflow__table {
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  margin-bottom: 20px;
  display: block;
  position: relative;
  overflow-x: hidden;
  overflow-x: auto;
  border-bottom: 1px solid $background;
  max-height: 400px;

  &.expanded {
    max-height: 1000px;
  }

  // Role Reset
  thead,
  tbody {
    display: block;
  }
  tr {
    display: flex;
    width: 100%;
  }
  th,
  td {
    flex: 1;
  }

  // Fixed header
  thead {
    position: sticky;
    top: 0;
    left: 0;
    background: #ffffff;
    border-bottom: 1px solid $background;
  }

  // Shared column styles
  th,
  td {
    padding: 7px 15px;
    text-align: left;
  }

  // Header
  th {
    font-weight: 700;
  }
  th.lime {
    background-color: $eon-lime;
  }
  th.turq {
    background-color: $eon-turquoise;
    color: #ffffff;
  }
  th.red {
    background-color: $eon-red;
    color: #ffffff;
  }
  th.bordeaux {
    background-color: $eon-bordeaux;
    color: #ffffff;
  }

  // Body
  tbody {
    border-left: 1px solid $background;
    border-right: 1px solid $background;
  }
  tbody tr:nth-child(odd) {
    background-color: $background-light;
  }
  td:not(:last-child) {
    border-right: 1px solid $copy-light;
  }

  // Column widths
  .year {
    flex: none;
    width: 70px;
  }
  .pag,
  .ai {
    flex: none;
    width: 130px;
  }
  .er,
  .ep {
    flex: none;
    width: 120px;
  }
}
