@import '@zing/neo-common/dist/scss/helpers/variables';

.voucher-codes__filters {
  margin-bottom: 30px;
}

.voucher-codes__table {
  width: 100%;
  table-layout: fixed;
}
.voucher-codes__table td,
.voucher-codes__table th {
  padding: 0.5rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.voucher-codes__table th {
  font-weight: bold;
}

.voucher-codes__import {
  max-width: 500px;
  margin: 0 auto;
  background: $background;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
}

.hollywood-validation-error {
  color: $eon-red-hover;
  font-weight: 600;
  font-size: 0.875rem;
}
