.presenter__slides-navigation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  padding: 40px;

  .slide-previews {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .slide-preview {
    display: inline-block;
    width: 130px;
    height: 97px;
    border: 2px solid #ffffff;
    overflow: hidden;

    iframe {
      display: block;
      width: 1024px;
      height: 768px;
      transform-origin: top left;
      transform: scale();
    }
  }
  .slide-preview + .slide-preview {
    margin-left: 15px;
  }
}
