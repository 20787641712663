@import '@zing/neo-common/dist/scss/helpers/variables';

.opportunities__date__label {
  position: sticky;
  top: 75px;
  z-index: $header-z - 1;
  display: inline-block;
}

.opportunity {
  display: block;
  padding: 20px;
}

.opportunity:nth-child(even) {
  background-color: $background-light;
}
.opportunity + .opportunity {
  border-top: 2px solid $background;
}
