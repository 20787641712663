.assign-to-modal .hollywood-modal__content {
  text-align: left;
  width: 600px;

  overflow: visible;
}

.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  border: 2px solid #e8e8e8;
  border-radius: 3px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background: #ffffff;
  z-index: 2;
  font-size: 16px;
}
.react-autosuggest__suggestions-container--open {
  display: block;
}

.react-autosuggest__suggestion {
  padding: 5px 8px;
  cursor: pointer;
}
.react-autosuggest__suggestion:hover {
  background-color: #e8e8e8;
}
