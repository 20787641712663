@import '@zing/neo-common/dist/scss/helpers/variables';

.notification {
  border-top: 2px solid $background;
  padding-top: 20px;
  margin-bottom: 20px;

  time {
    color: $copy-light;
    font-size: 14px;
  }
}
