@import '@zing/neo-common/dist/scss/helpers/variables';

.settings {
  .inputs,
  .discounts {
    margin-bottom: 60px;
  }
  .extend_form {
    margin-right: 16px;
    .border {
      color: $eon-red;
    }
    path {
      fill: $eon-red;
    }
    g {
      fill: white;
    }
  }
  .extend_form {
    &.inverted {
      path {
        fill: white;
      }
      g {
        fill: $eon-red;
      }
    }
  }
  .discountError {
    color: $eon-red;
  }
}
