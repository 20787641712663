@import '@zing/neo-common/dist/scss/helpers/variables';

.app-header {
  height: $headerHeight; // maintain height even if the contents change
  background-color: #ffffff;
  position: relative;
  position: sticky;
  top: 0;
  z-index: $header-z;

  // Multi coloured top border
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, $eon-turquoise, $eon-turquoise 75%, $eon-lime 75%, $eon-lime 83%, $eon-red 83%);
  }

  &.app-header--diagnostics {
    background-color: #121212;
    color: white;
  }

  // Vertically center contents
  .menu-btn,
  h1,
  .eon-logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .menu-btn {
    //reset
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    font: inherit;
    cursor: pointer;

    left: 20px;
    display: block;
    font-weight: 500;
    color: $eon-red;
    z-index: 2;

    &.diagnostics {
      color: white;
    }
  }
  .menu-btn.inverted {
    color: #ffffff;
  }

  h1 {
    width: 100%;
    left: 0;
    text-align: center;
    font-size: 24px;
    line-height: 26px;
    z-index: 1;
  }
  .eon-logo {
    right: 0;
    z-index: 2;
  }
}

.app-header--bordered {
  border-bottom: 1px solid $background;
}

.app-header--transparent {
  background: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: inherit;

  .menu-btn,
  .eon-logo {
    z-index: $header-z + 1;
  }

  &:before {
    display: none;
  }
}
