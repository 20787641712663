@import '@zing/neo-common/dist/scss/helpers/variables';

.product-status {
  display: inline-block;
  font-weight: 500;
  padding: 0.2em 0.5em 0.25em;
  line-height: 1;
  border-radius: 3px;
  color: #ffffff;
}

.product-status--Active {
  background-color: green;
}
.product-status--Retired {
  background-color: $eon-bordeaux;
}
