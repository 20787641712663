@import '@zing/neo-common/dist/scss/helpers/variables';

.status-toggle {
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  a {
    display: inline-block;
    text-align: center;
    min-width: 110px;
    padding: 8px;
    border: 2px solid #bfbfbf;
  }
  a:nth-child(1) {
    border-radius: 4px 0 0 4px;
    border-right: 0;
  }
  a:nth-child(2) {
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }
  a.active {
    color: #ffffff;
    font-weight: 900;
    background-color: $eon-red;
    border-color: $eon-red;
  }
}
