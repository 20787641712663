@import '@zing/neo-common/dist/scss/helpers/variables';

.before-we-start {
  .poi-checkboxes {
    margin-left: -20px;

    .hollywood-checkbox-label {
      margin: 0 0 20px 20px;
    }
  }
  .hollywood-validation-error {
    color: $eon-red-hover;
    font-weight: 900;
    font-size: 1.125rem;
  }
}
