@import '@zing/neo-common/dist/scss/helpers/variables';

.costs-and-savings {
  margin-bottom: 90px;

  .breakdown {
    padding: 0 30px;
  }
  .breakdown__section {
    padding-bottom: 15px;
    border-bottom: 5px solid;
    margin-bottom: 15px;

    &.total {
      border-color: $eon-lime;
    }
    &.financials {
      border-color: $eon-turquoise;
    }
    &.savings {
      border-color: $eon-red;
    }
  }

  .breakdown__section__row {
    padding: 7px 0;
    border-top: 2px solid $background;
  }

  .savings-image {
    background-size: cover;
    background-position: center center;
    position: relative;
    border-right: 35px solid $eon-lime; // rightmost border
    &:after {
      // inner border
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      background: $eon-turquoise;
    }
  }
}
