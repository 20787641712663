@import '@zing/neo-common/dist/scss/helpers/variables';
@import '@zing/neo-common/dist/scss/helpers/mixins';

.thank-you-for-choosing {
  padding: 0;
  .intro {
    background: $eon-red;
  }
  .intro-img {
    background-image: url('./intro-image.png');
    background-size: cover;
    background-position: center center;
    border-left: 10px solid $eon-turquoise;
    border-right: 20px solid $eon-lime;
  }
  .headline {
    padding: 100px 30px 50px 30px;
    color: #ffffff;
    border-left: 60px solid $eon-bordeaux;
  }

  h1,
  .h1 {
    font-size: 70px;
    line-height: 70px;
  }

  .page-content {
    padding: 40px 30px 30px 30px;
  }

  .next-steps {
    max-width: 640px;
    counter-reset: steps;
    li {
      counter-increment: steps;
      display: block;
      border-top: 4px solid;
      padding: 32px 0 24px 0;
      padding-left: 70px;
      position: relative;
      &:before {
        content: counter(steps);
        display: block;
        font-weight: bold;
        font-size: 24px;
        line-height: 44px;
        color: #ffffff;
        position: absolute;
        top: 24px;
        left: 0;
        width: 44px;
        height: 44px;
        text-align: center;
        background-size: 100%;
      }
    }

    li:nth-child(1) {
      border-color: $eon-lime;
      &:before {
        @include background-svg(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M3.38 3.42Q6.77-.37 22 0q15.22-.38 18.62 3.42T44 22q0 14.78-3.38 18.58T22 44q-15.23.42-18.62-3.38T0 22Q0 7.23 3.38 3.42z" fill="#{$eon-lime}"/></svg>'
        );
      }
    }
    li:nth-child(2) {
      border-color: $eon-red;
      &:before {
        @include background-svg(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M3.38 3.42Q6.77-.37 22 0q15.22-.38 18.62 3.42T44 22q0 14.78-3.38 18.58T22 44q-15.23.42-18.62-3.38T0 22Q0 7.23 3.38 3.42z" fill="#{$eon-red}"/></svg>'
        );
      }
    }
    li:nth-child(3) {
      border-color: $eon-turquoise;
      &:before {
        @include background-svg(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M3.38 3.42Q6.77-.37 22 0q15.22-.38 18.62 3.42T44 22q0 14.78-3.38 18.58T22 44q-15.23.42-18.62-3.38T0 22Q0 7.23 3.38 3.42z" fill="#{$eon-turquoise}"/></svg>'
        );
      }
    }
    li:nth-child(4) {
      border-color: $eon-bordeaux;
      &:before {
        @include background-svg(
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M3.38 3.42Q6.77-.37 22 0q15.22-.38 18.62 3.42T44 22q0 14.78-3.38 18.58T22 44q-15.23.42-18.62-3.38T0 22Q0 7.23 3.38 3.42z" fill="#{$eon-bordeaux}"/></svg>'
        );
      }
    }
  }

  .step-heading {
    position: relative;
    svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .step-description {
    padding-right: 40px;
  }
}
