@import '@zing/neo-common/dist/scss/helpers/variables';

.voucher-input__success,
.voucher-input__error {
  font-weight: 700;
  margin-top: 4px;
}
.voucher-input__success {
  color: darkgreen;
}
.voucher-input__error {
  color: $eon-bordeaux-hover;
}
