@import '@zing/neo-common/dist/scss/helpers/variables';

.energy-spend {
  padding: 0 30px 90px 30px;
}

.energy-spend__chart {
  position: relative;
  margin-top: 50px;
  margin-bottom: 20px;
}

.energy-spend__chart__key {
  width: 250px;
  position: absolute;
  top: 0;
  left: 0;

  $keySize: 30px;

  li {
    min-height: $keySize;
    position: relative;
    padding: 4px 0 0 $keySize + 10;
    margin-bottom: 10px;

    &:before {
      content: '';
      display: block;
      width: $keySize;
      height: $keySize;
      border-radius: $keySize / 2;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .current-spend:before {
    background: $eon-bordeaux;
  }
  .projected-spend:before {
    background: $eon-turquoise;
  }
  .savings:before {
    background: $eon-lime;
  }
}

.energy-spend__chart__bars {
  height: 380px;
  margin-bottom: 10px;

  .col {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 2px;
  }

  .bar {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    color: #ffffff;
    width: 100%;

    // position: absolute;
    // bottom: 0;
    // left: 2px;
    // right: 2px;
  }
  .bar.current {
    background-color: $eon-bordeaux;
  }
  .bar.projected {
    background-color: $eon-turquoise;
  }

  .savings-bubble {
    background-color: $eon-lime;
    font-weight: 900;
    border-radius: 12px;
    padding: 10px 0;
    color: $eon-bordeaux;
    text-align: center;
    margin: 0 6px 20px 6px;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
      background-color: $eon-lime;
      position: absolute;
      bottom: -8px;
      left: 50%;
      margin-left: -8px;
    }
  }
}
