@import '@zing/neo-common/dist/scss/helpers/variables';

$tabWidth: 60px;
$quoteReferenceWidth: 120px;
$salesFocusWidth: 120px;

.pipeline {
  padding: 90px 0 58px 0;
}

.pipeline_search {
  padding: 0 80px 30px 80px;

  #dateFrom,
  #dateTo {
    min-width: 150px;
  }
}

.pipeline_table {
  width: 100%;
  font-size: 14px;
  table-layout: fixed;
  background: #ffffff;
}

.pipeline_table_row,
.pipeline_table_header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 15px 30px;
}

.pipeline_table_header {
  position: sticky;
  top: 70px;
  background: $background-light;
  z-index: 1;
  font-weight: 900;
}

.pipeline_table_row.shaded {
  background: $background-light;
}

.pipeline_quote_is_offline {
  padding: 20px;
  font-size: 18px;
  font-weight: 900;
  &.shaded {
    background: $background-light;
  }
}

.pipeline_table_cell {
  text-align: left;
  flex: 1;

  // Sortable
  &.sortable:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    // Very light down arrow by default
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid $background;
    border-bottom: 0;
    margin-left: 8px;
    vertical-align: middle;
  }
  // Down (sorted 'desc')
  &.sortable.desc:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid $copy;
    border-bottom: 0;
  }
  // Up (sorted 'asc')
  &.sortable.asc:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 0;
    border-bottom: 7px solid $copy;
  }

  // column widths
  &.tab_col {
    width: $tabWidth;
    flex: none;
  }
  &.date_col {
    width: 120px;
    flex: none;
  }
  &.id_col {
    width: $quoteReferenceWidth;
    flex: none;
  }
  // ↑ needs to be the same width as id_col ↑
  &.quote_reference_col {
    width: $quoteReferenceWidth;
    flex: none;
  }
  &.city_col {
    width: 100px;
    min-width: 100px;
    flex: none;
  }
  &.signed_and_agreed_at_col {
    width: 120px;
    flex: none;
  }
  &.focus_col {
    width: $salesFocusWidth;
    flex: none;
  }
  &.sales_consultant_col {
    width: 120px;
    flex: none;
  }
  // ↑ needs to be the same width as focus_col ↑
  // columns are swapped for admins (Only in pipeline view)
  &.signed_agreed_col {
    width: $salesFocusWidth;
    flex: none;
  }
  &.status_reason_col {
    width: 130px;
    flex: none;
  }
  &.actions_col {
    width: 180px;
    flex: none;
    text-align: right;

    .hollywood-btn ~ .hollywood-btn {
      margin-left: 20px;
    }
  }

  &.expanded {
    font-weight: 900;
  }
}

.bold {
  font-weight: bold;
}
// over ride alignment for customer action buttons
.customer .actions_col {
  width: 200px;
}

.expand_btn {
  vertical-align: middle;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  border: 2px solid $eon-red;
}

// Sub rows
.subrow {
  td {
    border-top: 1px solid $background;
    padding: 10px 15px;
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      padding-right: 30px;
    }
  }
}

.no_results {
  margin: 100px 0;
  font-size: 18px;
  font-weight: 900;
}

// Quotes
.pipeline_table_quote_row {
  position: relative;
}

.pipeline_table_quote_row::after {
  content: ' ';
  position: absolute;
  height: 1px;
  background: $background;
  top: 0;
  left: 30px;
  right: 30px;
}

.pipeline_no_quotes {
  padding: 20px;
  font-size: 18px;
  font-weight: 900;

  &.shaded {
    background: $background-light;
  }
}

.pipeline_icon {
  color: $eon-red;

  &.offline {
    color: $copy-light;
  }
}
