@import '@zing/neo-common/dist/scss/helpers/variables';

#epc_search {
  svg.icon {
    margin-left: 100px;
  }
}

// EPC Toggle Colours
#epcrating-A:checked + .hollywood-toggle__btn {
  background-color: #037435;
  border-color: #037435;
}
#epcrating-B:checked + .hollywood-toggle__btn {
  background-color: #279425;
  border-color: #279425;
}
#epcrating-C:checked + .hollywood-toggle__btn {
  background-color: #93c435;
  border-color: #93c435;
}
#epcrating-D:checked + .hollywood-toggle__btn {
  background-color: #fff006;
  border-color: #fff006;
}
#epcrating-E:checked + .hollywood-toggle__btn {
  background-color: #f6a51c;
  border-color: #f6a51c;
}
#epcrating-F:checked + .hollywood-toggle__btn {
  background-color: #ea5d20;
  border-color: #ea5d20;
}
#epcrating-G:checked + .hollywood-toggle__btn {
  background-color: #df1a20;
  border-color: #df1a20;
}
