@import '@zing/neo-common/dist/scss/helpers/variables';

.solar-pv {
  .system-totals {
    padding: 20px;
    background-color: $eon-turquoise-hover;
    color: #ffffff;
    margin-bottom: 25px;
  }
  .roof {
    padding: 20px;
    background-color: $background;
    margin-bottom: 25px;
    .hollywood-input[type='number'] {
      width: 100px;
      flex: none;
    }
    .hollywood-input,
    .hollywood-toggle__btn {
      border-color: #bfbfbf;
    }
    .roof__image {
      margin-top: 16px;
    }
  }
  .roof__details {
    padding: 20px;
    background-color: #ffffff;
  }

  .roof__name,
  .roof__name-input {
    display: inline-block;
    margin: 0 20px 0 0;
    vertical-align: middle;
  }
  .roof__name-input {
    width: 300px;
  }

  .comments-col {
    width: 600px;
  }

  .sunpath-buttons {
    white-space: nowrap;
  }

  @media screen and (max-width: 880px) {
    .sunpath-buttons {
      flex: none;
      width: 100%;
    }
  }

  .edit-sunpath-btn {
    font-weight: 900;
    color: #ffffff;
    background-color: #008704;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 10px;
    white-space: nowrap;
    .icon {
      width: 1em;
      height: 1em;
      vertical-align: -1px;
      margin-right: 5px;
    }
  }
}

.edit-roof-name-modal .hollywood-modal__content {
  width: 400px;
}
